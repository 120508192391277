/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import "primeicons/primeicons.css";
@import 'primeflex/primeflex.css';


/* ionic overrides */
ion-card {
  &.outline {
    outline: 2px solid #6c6c6c !important;
  }
}

ion-footer {
  &.dark {
    background: var(--ion-color-dark) !important;
  }
}

/* extract into shared lib stylesheet */
// use css variables, to re-use color definitions across color/bg/border etc.

$prfBlueBg: #eceff4;

$prfSuccessColor: #188a42; // green-700
.prf-color-success {
  color: $prfSuccessColor !important;
}

.prf-border-success {
  border-color: $prfSuccessColor !important;
  outline-color: $prfSuccessColor !important;
}

ion-footer {
  .ion-padding {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}


// blurry text inside ion-modal
// https://github.com/ionic-team/ionic-framework/issues/26159
// fix doesnt seem to work
//ion-modal::part(handle) {
//  transform: none !important;
//}


ion-refresher-content {
  box-shadow: inset 0px -10px 10px -5px rgba(0, 0, 0, 0.2);
  background: #eceff4; // $prfBlueBg
}

// This should be the container that is visible during refresher dragging. However, the style here isn't being applied.
//ion-content {
//  //.inner-scroll {
//  #background-content {
//    //box-shadow: 0px -3px 10px -5px rgba(0,0,0,0.2);
//    //box-shadow: 0 1px 22px 0 rgba(0,0,0,0.2);
//  }
//}
